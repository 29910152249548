@import './shared/styles/_vuetify-overrides.scss';

























































































































































































.cassie-data-subject-associated-devices-expansion-panels {
	.v-expansion-panel-header {
		min-height: 32px !important;
		padding: 12px !important;
	}
	.v-expansion-panel-content {
		padding: 0 !important;
	}
	.v-expansion-panel-content__wrap {
		padding: 16px !important;
	}
	.v-expansion-panel--active:not(:first-child), .v-expansion-panel--active + .v-expansion-panel {
		margin-top: 0 !important;
	}
}
